export const DATA = { 
    "U R U": 0.22,
    "U R U2": 0.2,
    "U R U'": 0.17,
    "U R F": 0.34,
    "U R F2": 0.52,
    "U R F'": 0.19,
    "U R2 U": 0.31,
    "U R2 U2": 0.31,
    "U R2 U'": 0.3,
    "U R2 F": 0.28,
    "U R2 F2": 0.35,
    "U R2 F'": 0.31,
    "U R' U": 0.19,
    "U R' U2": 0.21,
    "U R' U'": 0.18,
    "U R' F": 0.25,
    "U R' F2": 0.36,
    "U R' F'": 0.17,
    "U F U": 0.41,
    "U F U2": 0.38,
    "U F U'": 0.34,
    "U F R": 0.25,
    "U F R2": 0.27,
    "U F R'": 0.35,
    "U F2 U": 0.49,
    "U F2 U2": 0.46,
    "U F2 U'": 0.4,
    "U F2 R": 0.35,
    "U F2 R2": 0.4,
    "U F2 R'": 0.4,
    "U F' U": 0.33,
    "U F' U2": 0.42,
    "U F' U'": 0.3,
    "U F' R": 0.29,
    "U F' R2": 0.42,
    "U F' R'": 0.33,
    "U2 R U": 0.26,
    "U2 R U2": 0.27,
    "U2 R U'": 0.23,
    "U2 R F": 0.38,
    "U2 R F2": 0.48,
    "U2 R F'": 0.33,
    "U2 R2 U": 0.4,
    "U2 R2 U2": 0.33,
    "U2 R2 U'": 0.27,
    "U2 R2 F": 0.24,
    "U2 R2 F2": 0.36,
    "U2 R2 F'": 0.36,
    "U2 R' U": 0.25,
    "U2 R' U2": 0.26,
    "U2 R' U'": 0.22,
    "U2 R' F": 0.21,
    "U2 R' F2": 0.26,
    "U2 R' F'": 0.23,
    "U2 F U": 0.38,
    "U2 F U2": 0.41,
    "U2 F U'": 0.36,
    "U2 F R": 0.31,
    "U2 F R2": 0.32,
    "U2 F R'": 0.37,
    "U2 F2 U": 0.49,
    "U2 F2 U2": 0.51,
    "U2 F2 U'": 0.48,
    "U2 F2 R": 0.37,
    "U2 F2 R2": 0.38,
    "U2 F2 R'": 0.5,
    "U2 F' U": 0.46,
    "U2 F' U2": 0.6,
    "U2 F' U'": 0.43,
    "U2 F' R": 0.36,
    "U2 F' R2": 0.49,
    "U2 F' R'": 0.4,
    "U' R U": 0.23,
    "U' R U2": 0.33,
    "U' R U'": 0.16,
    "U' R F": 0.36,
    "U' R F2": 0.46,
    "U' R F'": 0.26,
    "U' R2 U": 0.34,
    "U' R2 U2": 0.29,
    "U' R2 U'": 0.25,
    "U' R2 F": 0.26,
    "U' R2 F2": 0.41,
    "U' R2 F'": 0.32,
    "U' R' U": 0.24,
    "U' R' U2": 0.33,
    "U' R' U'": 0.27,
    "U' R' F": 0.17,
    "U' R' F2": 0.26,
    "U' R' F'": 0.23,
    "U' F U": 0.38,
    "U' F U2": 0.37,
    "U' F U'": 0.3,
    "U' F R": 0.28,
    "U' F R2": 0.32,
    "U' F R'": 0.34,
    "U' F2 U": 0.42,
    "U' F2 U2": 0.47,
    "U' F2 U'": 0.47,
    "U' F2 R": 0.42,
    "U' F2 R2": 0.48,
    "U' F2 R'": 0.55,
    "U' F' U": 0.43,
    "U' F' U2": 0.39,
    "U' F' U'": 0.32,
    "U' F' R": 0.34,
    "U' F' R2": 0.4,
    "U' F' R'": 0.39,
    "R U R": 0.21,
    "R U R2": 0.29,
    "R U R'": 0.21,
    "R U F": 0.4,
    "R U F2": 0.54,
    "R U F'": 0.34,
    "R U2 R": 0.38,
    "R U2 R2": 0.31,
    "R U2 R'": 0.28,
    "R U2 F": 0.37,
    "R U2 F2": 0.49,
    "R U2 F'": 0.37,
    "R U' R": 0.26,
    "R U' R2": 0.26,
    "R U' R'": 0.22,
    "R U' F": 0.4,
    "R U' F2": 0.5,
    "R U' F'": 0.41,
    "R F U": 0.42,
    "R F U2": 0.46,
    "R F U'": 0.46,
    "R F R": 0.44,
    "R F R2": 0.44,
    "R F R'": 0.33,
    "R F2 U": 0.47,
    "R F2 U2": 0.47,
    "R F2 U'": 0.47,
    "R F2 R": 0.49,
    "R F2 R2": 0.45,
    "R F2 R'": 0.46,
    "R F' U": 0.35,
    "R F' U2": 0.51,
    "R F' U'": 0.25,
    "R F' R": 0.25,
    "R F' R2": 0.31,
    "R F' R'": 0.28,
    "R2 U R": 0.32,
    "R2 U R2": 0.34,
    "R2 U R'": 0.32,
    "R2 U F": 0.37,
    "R2 U F2": 0.51,
    "R2 U F'": 0.36,
    "R2 U2 R": 0.32,
    "R2 U2 R2": 0.34,
    "R2 U2 R'": 0.31,
    "R2 U2 F": 0.38,
    "R2 U2 F2": 0.41,
    "R2 U2 F'": 0.49,
    "R2 U' R": 0.27,
    "R2 U' R2": 0.27,
    "R2 U' R'": 0.26,
    "R2 U' F": 0.35,
    "R2 U' F2": 0.4,
    "R2 U' F'": 0.42,
    "R2 F U": 0.33,
    "R2 F U2": 0.36,
    "R2 F U'": 0.34,
    "R2 F R": 0.25,
    "R2 F R2": 0.22,
    "R2 F R'": 0.36,
    "R2 F2 U": 0.4,
    "R2 F2 U2": 0.41,
    "R2 F2 U'": 0.38,
    "R2 F2 R": 0.33,
    "R2 F2 R2": 0.35,
    "R2 F2 R'": 0.39,
    "R2 F' U": 0.35,
    "R2 F' U2": 0.38,
    "R2 F' U'": 0.34,
    "R2 F' R": 0.26,
    "R2 F' R2": 0.27,
    "R2 F' R'": 0.34,
    "R' U R": 0.19,
    "R' U R2": 0.3,
    "R' U R'": 0.26,
    "R' U F": 0.33,
    "R' U F2": 0.44,
    "R' U F'": 0.36,
    "R' U2 R": 0.27,
    "R' U2 R2": 0.28,
    "R' U2 R'": 0.3,
    "R' U2 F": 0.35,
    "R' U2 F2": 0.4,
    "R' U2 F'": 0.43,
    "R' U' R": 0.14,
    "R' U' R2": 0.18,
    "R' U' R'": 0.29,
    "R' U' F": 0.3,
    "R' U' F2": 0.4,
    "R' U' F'": 0.37,
    "R' F U": 0.28,
    "R' F U2": 0.28,
    "R' F U'": 0.26,
    "R' F R": 0.2,
    "R' F R2": 0.2,
    "R' F R'": 0.24,
    "R' F2 U": 0.33,
    "R' F2 U2": 0.34,
    "R' F2 U'": 0.34,
    "R' F2 R": 0.24,
    "R' F2 R2": 0.26,
    "R' F2 R'": 0.44,
    "R' F' U": 0.28,
    "R' F' U2": 0.32,
    "R' F' U'": 0.26,
    "R' F' R": 0.2,
    "R' F' R2": 0.22,
    "R' F' R'": 0.38,
    "F R U": 0.32,
    "F R U2": 0.26,
    "F R U'": 0.23,
    "F R F": 0.265,
    "F R F2": 0.26,
    "F R F'": 0.24,
    "F R2 U": 0.29,
    "F R2 U2": 0.3,
    "F R2 U'": 0.28,
    "F R2 F": 0.46,
    "F R2 F2": 0.4,
    "F R2 F'": 0.33,
    "F R' U": 0.37,
    "F R' U2": 0.31,
    "F R' U'": 0.26,
    "F R' F": 0.28,
    "F R' F2": 0.34,
    "F R' F'": 0.22,
    "F U R": 0.39,
    "F U R2": 0.39,
    "F U R'": 0.39,
    "F U F": 0.39,
    "F U F2": 0.48,
    "F U F'": 0.53,
    "F U2 R": 0.4,
    "F U2 R2": 0.39,
    "F U2 R'": 0.41,
    "F U2 F": 0.45,
    "F U2 F2": 0.47,
    "F U2 F'": 0.46,
    "F U' R": 0.37,
    "F U' R2": 0.37,
    "F U' R'": 0.39,
    "F U' F": 0.41,
    "F U' F2": 0.49,
    "F U' F'": 0.42,
    "F2 R U": 0.37,
    "F2 R U2": 0.33,
    "F2 R U'": 0.3,
    "F2 R F": 0.35,
    "F2 R F2": 0.36,
    "F2 R F'": 0.29,
    "F2 R2 U": 0.33,
    "F2 R2 U2": 0.34,
    "F2 R2 U'": 0.31,
    "F2 R2 F": 0.45,
    "F2 R2 F2": 0.5,
    "F2 R2 F'": 0.43,
    "F2 R' U": 0.49,
    "F2 R' U2": 0.58,
    "F2 R' U'": 0.48,
    "F2 R' F": 0.32,
    "F2 R' F2": 0.35,
    "F2 R' F'": 0.28,
    "F2 U R": 0.44,
    "F2 U R2": 0.47,
    "F2 U R'": 0.53,
    "F2 U F": 0.51,
    "F2 U F2": 0.57,
    "F2 U F'": 0.6,
    "F2 U2 R": 0.49,
    "F2 U2 R2": 0.52,
    "F2 U2 R'": 0.5,
    "F2 U2 F": 0.48,
    "F2 U2 F2": 0.61,
    "F2 U2 F'": 0.52,
    "F2 U' R": 0.42,
    "F2 U' R2": 0.42,
    "F2 U' R'": 0.41,
    "F2 U' F": 0.5,
    "F2 U' F2": 0.54,
    "F2 U' F'": 0.52,
    "F' R U": 0.23,
    "F' R U2": 0.3,
    "F' R U'": 0.23,
    "F' R F": 0.32,
    "F' R F2": 0.43,
    "F' R F'": 0.28,
    "F' R2 U": 0.36,
    "F' R2 U2": 0.35,
    "F' R2 U'": 0.29,
    "F' R2 F": 0.38,
    "F' R2 F2": 0.5,
    "F' R2 F'": 0.46,
    "F' R' U": 0.4,
    "F' R' U2": 0.37,
    "F' R' U'": 0.32,
    "F' R' F": 0.33,
    "F' R' F2": 0.43,
    "F' R' F'": 0.34,
    "F' U R": 0.35,
    "F' U R2": 0.41,
    "F' U R'": 0.33,
    "F' U F": 0.39,
    "F' U F2": 0.48,
    "F' U F'": 0.4,
    "F' U2 R": 0.39,
    "F' U2 R2": 0.51,
    "F' U2 R'": 0.4,
    "F' U2 F": 0.44,
    "F' U2 F2": 0.51,
    "F' U2 F'": 0.4,
    "F' U' R": 0.31,
    "F' U' R2": 0.42,
    "F' U' R'": 0.37,
    "F' U' F": 0.41,
    "F' U' F2": 0.53,
    "F' U' F'": 0.49
};

